import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import loadable from "@loadable/component";
import NoMatch from "./common/NoMatch";

const Home = loadable(() => import("./views/Home"));
const Questions = loadable(() => import("./views/Questions"));

function App() {
  return (
    <Suspense fallback={<div>...</div>}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/questions" element={<Questions />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
